import * as React from "react";
import "@aws-amplify/ui/dist/style.css";
import BoothItem from "components/pages/front/BoothItem";
import Layout from "components/layouts/Common";
import { Helmet } from "react-helmet";
import { GatsbySeo } from "gatsby-plugin-next-seo";
type Props = {
  pageContext: any;
};

export default function App(props: Props) {
  const { pageContext } = props;
  const { show, boothItem, info, booth } = pageContext;
  return (
    <div className="App">
      {show && booth && boothItem && (
        <GatsbySeo
          title={`${show.title} ${boothItem.name} ${boothItem.readingName}`}
          description={`${show.description} ${boothItem.name} ${boothItem.readingName}`}
          openGraph={{
            url: `https://www.onlineboatshow.jp/${show.slug}/boothitem/${boothItem.id}`,
            title: `${show.title} ${boothItem.name} ${booth.name}`,
            description: `${show.description} ${boothItem.name} ${boothItem.readingName}`,
            site_name: "ONLINE BOATSHOW オンラインボートショー ",
            images: [
              {
                url:
                  boothItem.mainImage && boothItem.mainImage.url
                    ? boothItem.mainImage.url
                    : show.opgImage
                    ? show.opgImage.url
                    : "",
                alt: `${booth.name} ${boothItem.name}`,
              },
            ],
          }}
        />
      )}
      <Helmet>
        <title>{`${booth.name} ${boothItem.name} - ${show.title}`}</title>
      </Helmet>
      <Layout show={show}>
        <BoothItem
          show={pageContext.show}
          booth={booth}
          boothItem={boothItem}
          info={info}
        />
      </Layout>
    </div>
  );
}
