import * as React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import PhoneIcon from "@material-ui/icons/Phone";
import HomeIcon from "@material-ui/icons/Home";
import WebIcon from "@material-ui/icons/Web";
import Typography from "@material-ui/core/Typography";

type Props = {
  data: any;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: "#333",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      backgroundColor: theme.palette.background.paper,
    },
    item: {
      maxWidth: 320,
    },
  })
);
export default function BoothUserInfo(props: Props) {
  const classes = useStyles();
  const { data } = props;
  return (
    <>
      <Typography style={{ color: "#333" }}>{data.contactTelInfo}</Typography>
      <List className={classes.root}>
        {data.contactEmail && (
          <ListItem
            className={classes.item}
            button
            component="a"
            href={`mailto:${data.contactEmail}`}
          >
            <ListItemAvatar>
              <Avatar>
                <HomeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="メールでのお問い合わせ"
              secondary={data.contactEmail}
            />
          </ListItem>
        )}

        {data.contactTelInfo && (
          <ListItem
            className={classes.item}
            button
            component="a"
            href={`tel:${data.contactEmail}`}
          >
            <ListItemAvatar>
              <Avatar>
                <PhoneIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="電話でのお問い合わせ"
              secondary={`${data.contactTel}`}
            />
          </ListItem>
        )}

        {data.contactAddress && (
          <ListItem className={classes.item}>
            <ListItemAvatar>
              <Avatar>
                <HomeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="住所" secondary={data.contactAddress} />
          </ListItem>
        )}

        {data.web && (
          <ListItem
            className={classes.item}
            button
            component="a"
            target="_blank"
            href={`${data.web}`}
          >
            <ListItemAvatar>
              <Avatar>
                <WebIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Web" secondary={data.web} />
          </ListItem>
        )}
        {data.facebook && (
          <ListItem
            className={classes.item}
            button
            component="a"
            target="_blank"
            href={`${data.facebook}`}
          >
            <ListItemAvatar>
              <Avatar>
                <Icon className="fab fa-facebook" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Facebook" />
          </ListItem>
        )}
      </List>
    </>
  );
}
