import React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link } from "gatsby";
import MuiLink from "@material-ui/core/Link";

function handleClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}
type Props = {
  show?: any;
  booth?: any;
  boothItem?: any;
};

export default function ActiveLastBreadcrumb(props: Props) {
  const { show, booth, boothItem } = props;
  return (
    <Breadcrumbs style={{ padding: 8 }} aria-label="breadcrumb">
      {show && (
        <MuiLink color="inherit" component={Link} to={`/${show.slug}`}>
          {show.name}
        </MuiLink>
      )}
      {booth && (
        <MuiLink
          color="inherit"
          component={Link}
          to={`/${show.slug}/booth/${booth.id}`}
        >
          {booth.name}
        </MuiLink>
      )}
      {boothItem && (
        <MuiLink
          color="inherit"
          component={Link}
          to={`/${show.slug}/boothitem/${boothItem.id}`}
        >
          {boothItem.name}
        </MuiLink>
      )}
    </Breadcrumbs>
  );
}
